<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Ý kiến khách hàng </a></li>
    </ol>
    <div class="container-fluid">
      <div class="col-sm-10">
        <div class="form-group">
          <router-link to="/admin/question/add" class="btn btn-sm btn-primary">
            <i class="fas fa-plus"></i>
            Thêm mới câu hỏi
          </router-link>
        </div>
      </div>

      <div>
        <div>
          <div class="row">
            <div class="col-sm-5 mb-3">
              <input
                type="text"
                name="name"
                class="form-control"
                v-model="search"
                autocomplete="off"
                placeholder="Nhập tên câu hỏi..."
              /><button class="btn btn-sm btn-warning mt-3" @click="onSearch">
                <i class="fa fa-search"></i> TÌM KIẾM
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <Skeleton width="40px" height="40"></Skeleton>
              </SkeletonTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify"></i> Danh sách phản hồi khách hàng
              : ( {{ mydata.length }} )
            </div>
            <div class="card-block">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>Tên</th>
                      <th>Chi tiết</th>
                      <th>Tuỳ chọn</th>
                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 10" :key="k">
                      <td>
                        <Skeleton width="40px" height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>

                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                        <br />

                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(value, key) in paginationslice(mydata, 10, page)"
                      :Key="key"
                    >
                      <td>
                        Tên: {{ value.name }} <br />
                        SĐT: {{ value.phone }}<br />
                        EMAIL: {{ value.email }}
                      </td>
                      <td>
                        <div class="text text-danger border m-1">
                          Câu hỏi:
                          <div v-html="value.questionName"></div>
                        </div>

                        <div
                          class="text text-primary border"
                          v-if="value.awesome"
                        >
                          <div v-html="value.awesome"></div>
                        </div>
                      </td>
                      <td v-if="id_load !== value.id">
                        <router-link
                          class="btn btn-sm btn-success"
                          v-bind:to="'/admin/question/edit/' + value.id"
                        >
                          <i class="fa fa-edit"></i>
                          Sửa
                        </router-link>
                        <div
                          class="btn btn-sm btn-danger click_remove"
                          @click="removeElement(value.id)"
                        >
                          <i class="fa fa-remove"></i> Xoá
                        </div>
                      </td>
                      <td v-else>
                        <h4>Loading...</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <nav v-if="mydata != null && mydata.length > 10">
                  <VuePagination
                    :page-count="Math.ceil(mydata.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </SkeletonTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
import axios from "axios";
export default {
  name: "News.List",
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      search: "",
      page: 1,
      id_load: "",
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
  },

  mounted() {
    axios
      .get(window.my_api + "api/question/get-questions", 
          {
              headers: window.my_header
            }
        )
      .then((res) => {
        if (res.status == 200) {
          this.mydata = res.data.data;
          this.is_loading = false;
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });
  },
  methods: {
    changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    onSearch() {
      this.is_loading = true;
      axios
        .get(
          window.my_api + "api/question/get-questions?Search=" + this.search,
           {
              headers: window.my_header
            }
        )
        .then((res) => {
          if (res.status == 200) {
            this.mydata = res.data.data;
            this.page = 1;
          } else {
            alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
          }
          this.is_loading = false;
        });
    },
    removeElement(id) {
    if(confirm("Bạn muốn xoá?")){
            this.id_load = id;
            axios
              .delete(window.my_api + "api/question/remove-question", {
                data: { questionIds: [id] },

                headers: window.my_header
            
              })
              .then((res) => {
                if (res.status == 200) {
                  alert("Xoá thành công");
                  this.mydata = this.mydata.filter((el) => el.id !== id);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
      
    },
  },
};
</script>








